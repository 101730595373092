import React, { useState } from "react";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { DelayInput } from "react-delay-input/lib/Component";
import Layout from "../components/layout";
import CatList from "../components/catList";
import LetterPagination from "../components/letterPagination";
import SEO from "../components/seo";

function KategorienAlphabetisch() {
  const [search, setSearch] = useState("");
  let letter = "a";

  if (typeof window !== `undefined`) {
    const url = new URL(window.location);
    letter = url.searchParams.get("letter");
  }
  const cache = new InMemoryCache();
  const client = new ApolloClient({
    cache,
  });

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  return (
    <ApolloProvider client={client}>
      <SEO title="Kategorien alphabetisch" />

      <Layout>
        <main className="bg-lightGrey flex-1">
          <div className="space-y-12 mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
            <div className="col-span-3 space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
              <h1 className="text-3xl text-primary-dark font-extrabold tracking-tight sm:text-4xl">
                Kategorien <span className="text-secondary">alphabetisch</span>
              </h1>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-6">
              <div className="md:col-span-4 lg:col-span-5">
                <LetterPagination
                  letter={search === "" ? letter : ""}
                  callbackSearch={setSearch}
                />
              </div>
              <div className="md:col-span-2 lg:col-span-1">
                <label htmlFor="search_field" className="sr-only">
                  Search
                </label>
                <div className="relative w-full pr-2">
                  <div className="pointer-events-none absolute inset-y-0 left-3 flex items-center text-secondary">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M9 9a2 2 0 114 0 2 2 0 01-4 0z" />
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a4 4 0 00-3.446 6.032l-2.261 2.26a1 1 0 101.414 1.415l2.261-2.261A4 4 0 1011 5z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <DelayInput
                    minLength={3}
                    delayTimeout={300}
                    onChange={handleSearch}
                    className="block h-full w-full rounded border-transparent py-1 m-1 pl-8 pr-3 placeholder-secondary
                                placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0
                                focus:border-transparent font-bold text-sm"
                    placeholder="Suche"
                    value={search}
                    type="search"
                    name="search"
                  />
                </div>
              </div>
            </div>
            <CatList letter={search === "" ? letter : ""} search={search} />
          </div>
        </main>
      </Layout>
    </ApolloProvider>
  );
}

export default KategorienAlphabetisch;
