import { gql, useQuery } from "@apollo/client";
import React from "react";
import Link from "gatsby-link";
import CoverPlaceholder from "../images/vinyl-record-svgrepo-com.svg";

const thisQuery = gql`
  query GetCategories($letter: String, $pagetitle: String) {
    categories(
      sort: "menutitle"
      where: { letterFilter_contains: $letter, pagetitle_contains: $pagetitle }
      limit: 299
    ) {
      id
      pagetitle
      menutitle
    }
  }
`;

function CatList({ letter, search }) {
  const { loading, error, data } = useQuery(thisQuery, {
    variables: {
      letter: letter?.toLowerCase(),
      pagetitle: search === "" ? "" : search,
    },
  });

  if (loading)
    return (
      <div>
        <div className="align-middle w-full text-center filter drop-shadow-lg">
          <img
            className="h-32 m-32 animate-spin m-auto"
            src={CoverPlaceholder}
            alt="Loading..."
          />
          <h3 className="text-lg leading-6 font-medium space-y1 mt-8 text-primary-dark animate-pulse font-bold">
            Serial Records loading...
          </h3>
        </div>
      </div>
    );
  if (error) return `Error! ${error.message}`;

  return (
    <div>
      {data !== undefined && (
        <div
          className={data.categories.length >= 20 ? "auto-columns" : undefined}
        >
          {data.categories.map((category) => (
            <ul key={category.id}>
              <Link to={`/category?id=${category.id}`}>
                <li>
                  <span className="text-secondary">&minus;</span>
                  <span className="ml-2 border-transparent hover:border-secondary border-b-2 font-regular">
                    {category.pagetitle}
                  </span>
                </li>
              </Link>
            </ul>
          ))}
        </div>
      )}
    </div>
  );
}

export default CatList;
